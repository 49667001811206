* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
  background-color: #f2f2f2;
  /* font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif; */

  /* font-family: Arial,Helvetica,sans-serif; */
}

@media only screen and (max-width: 1000px) {
  h1,
  h1 span {
    font-size: 20px !important;
    text-align: center !important;
  }
  .h-parent .span {
    font-size: 30px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px !important;
  }
  p {
    font-size: 15px !important;
  }
  .h-parent .css-104xcp1 {
    padding: 0px;
  }
  .h-parent  .css-u8jde7-MuiTypography-root {
    font-size: 20px !important;
  }
  .css-pl0v1f {
    transform: translateX(20px) !important;
  }
  .none  {
    height: 80px !important;

  }
}

@media only screen and (max-width :  400px)  {
  .css-rhuvky {
    padding-right: 20px;
  }
  .none {
    display: none !important;
  }
}
