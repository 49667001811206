/************************************************navbar style*******************************************************************/

.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  background-color: white !important;
  box-shadow: 0px 2px 2px lightgray !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root img {
  width: 250px;
  margin-left: 20px;
}
.h-parent .home a, .h-parent, .css-i4bv87-MuiSvgIcon-root,.css-92dkis-MuiTypography-root , .css-y1ww2x-MuiTypography-root ,.css-1ebuga2-MuiTypography-root , .css-gpqios-MuiTypography-root , .css-10cetm8-MuiTypography-root , .css-99j32i-MuiTypography-root , .css-1isgw3a-MuiTypography-root , .css-11xxht9-MuiTypography-root , .css-zwf83e-MuiTypography-root{

  font-size: 18px !important;
  line-height: 23px !important;
}

.css-141nndb-MuiTypography-root {
  font-size: 20px !important;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root a,
header a {
  color: black !important;
  font-family: questrial, sans-serif;
  padding: 10px !important ;
  display: inline-block !important;
  text-decoration: none !important;
  transition: 1s !important;
  font-size: 18px;
  font-weight: 550;
}
.contact-link:hover{
  color: white !important;

}
.contact-link {
  color: white !important;
padding: 10px 20px !important;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root a:hover,
header a:hover {
  color: #ef3575 !important;
  cursor: pointer !important;
  transition: 1s;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root a:active,
header a:active {
  color: #ef3575 !important;
}

/* ***********************************************************footer style **************************************************************/
.footer {
  padding: 70px 40px;
}
.footer a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.icons a {
  color: #293e5a;
  display: flex;
  align-items: center;
  align-content: center;
  text-decoration: none;
  padding: 3px 6px;
  padding-top: 6px;
  display: inline-block;
  border-radius: 50%;
  background-color: white;
}
/******************************************************************** background ********************************************/

.home-parent {
  position: relative !important;
}
.h-parent {

  position: relative !important;
}
.h-parent .home {
  min-height: 80vh !important;
  border-radius: 0px 0px 50px 50px !important;
}
.css-1dqwxp2-MuiTypography-root {
  margin-top: 50px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 15px !important;
  height: 15px !important;
}
.h-parent .home .parent {

  position: absolute;
  padding: 50px ;

  /* border-radius: 50px; */
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;

}
.css-19ynkn6-MuiGrid-root {
  text-align: left !important;
}

.h-parent .home {
  background-image: url("../images/back.png");
background-repeat: no-repeat;
background-position: center;
background-size: cover;

}
.home .parent span {
  display: block;
  margin-bottom: 20px;
}
.home h1 {
  font-size: 36px;
  font-weight: bold;
}
.home form input {
  display: block;
  padding: 10px;
  padding-left: 35px;
  border-radius: 10px;
  border-color: greenyellow;
  border-style: solid;
  min-width: 60%;
  margin-top: 20px;
}

.left {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  animation: abs 1 1s;
  width: 70px;
  height: 300px;
}
.right {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(0%) scale(-1);
  width: 70px;
  height: 300px;
  animation: abs 1 1s;
}

.css-xnhscb-MuiCardMedia-root {
  min-width: 350px;
}
@keyframes abs {
  from {
    width: 0px;
    opacity: 0;
  }
  to {
    opacity: 1;
    width: 80px;
  }
}
.css-1dqwxp2-MuiTypography-root {
  margin-bottom: 0px !important;
  margin-bottom: 50px !important;
}
.virtual-parent {
  position: relative !important;
}
.home-parent .third {
  border-radius: 50px;
  margin: auto;
  margin-top: 50px;
  min-height: 90vh;
}


.virtual-parent .home .parent {
  padding: 10px !important;
}
.virtual-parent .home .parent h1 {
  padding: 30px 0px !important;
}
.virtual-parent .home {
  border-radius: 50px;
  margin: auto !;
}
.h-parent .home a,
.h-parent,
.css-i4bv87-MuiSvgIcon-root {
  color: #757575;
  transition: 0.7s;
  font-size: 20px;
  text-decoration: none;
  text-align: left !important;
}
.h-parent .home a:hover {
  transition: 0.7s;
  color: #ef3575;
}
.h-parent .home {
  margin-top: 115px !important;
}
.calling .home {
  height: 83vh;
  margin-top: 115px;
  border-radius: 0px 0px 50px 50px;

  display: flex;
  align-items: center;
  background-image: url("../images/image-yellow.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  object-position: 80% 50%;
  object-fit: cover;
}
.virtual-parent .home {
  height: 83vh;
  display: flex;
  border-radius: 0px 0px 50px 50px;

  align-items: center;
  background-image: url("../images/num.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  object-position: 80% 50%;
  margin-top: 115px;
  object-fit: cover;
}
.esim-parent .home {
  height: 83vh;
  border-radius: 0px 0px 50px 50px;

  display: flex;
  align-items: center;
  background-image: url("../images/esim.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
  object-position: 80% 50%;
  object-fit: cover;
  margin-top: 115px;
}

@media only screen and (max-width: 950px) {
  .calling .home {
    background-image: url("../images/call.webp");
    object-position: 80% 50%;

    height: 76vh;
    margin-top: 75px;
  }
  .virtual-parent .home {
    background-image: url("../images/shutterstock_2303495945\ -\ Copy.webp");
    height: 76vh;
    object-position: 80% 50%;
    margin-top: 75px;
  }

  .esim-parent .home {
    background-image: url("../images//shutterstock_1191546529_edited.webp");
    height: 76vh;
    object-position: 80% 50%;
    margin-top: 75px;
  }
}
@media only screen and (min-width : 100px) {
  .img {

    left: 200px !important;
    transform: rotate(90deg) !important;
  
  }
}
@media only screen and (max-width: 900px) {
  .img {
    display: none;
  }
  .h-parent .home .parent {
    padding: 20px;
  }
  .links {
    transition: 1s;
    position: fixed;
    top: 0px;
    right: -100%;
    height: 100vh;
    width: 100%;
    display: flex;
    font-size: 25px;
    justify-content: center;
    align-items: center;

    background-color: white;
  }
  .links .nav {
    flex-direction: column;
  }
  .links a {
    color: black !important;
    text-align: center !important;
    display: block !important;
  }
  .links a:nth-child(4) {
    color: #757575 !important;
  }
  .links img {
    display: block;
    margin-top: 100px;
  }
  .home {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home .parent,
  #parent {
    transform: translateX(0px) !important;
    width: 90% !important;
    /* position: absolute; */
  }
  .virtual-parent {
    position: relative;
  }

  .home .parent input {
    min-width: 80%;
  }
  .left {
    height: 200px;
    width: 50px;
  }
  .right {
    width: 50px;

    height: 200px;
  }
}

.hover:hover {
  background-color: white !important;
  color: black;
  border: 2px solid black;
  transition: 1s;
}

.css-1yrpq6u {
  padding: 0px !important;
}
.css-4zlaj2 {
  height: auto !important;
}

.css-1ktultt {
  padding: 30px 10px !important;
}
.css-112kzm7-MuiCardMedia-root {
  left: 118px !important;
}

